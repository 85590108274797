//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { faShoppingCart } from '@motionelements/assets/src/js/me-font/icons-fa6';

export default {
  name: 'CartNavDropdown',
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  components: {
    CartNavList: () => import('./CartNavList.vue'),
  },
  data() {
    return {
      faShoppingCart,
    };
  },
  computed: {
    ...mapGetters({
      getTotalItemsCount: 'cart/getTotalItemsCount',
    }),
  },
  methods: {
    emitOnShow() {
      this.$emit('onShow');
    },
  },
};
