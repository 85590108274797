//
//
//
//
//
//

import RecentlyVisitedList from '@motionelements/core/src/components/nav/RecentlyVisitedList.vue';

export default {
  name: 'NavRecentlyVisitedList',
  components: {
    RecentlyVisitedList,
  },
  props: {
    isTransparent: {
      type: Boolean,
      required: true,
    },
    showItems: {
      type: Number,
      default: 3,
    },
  },
};
